import React from 'react'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { useSelector } from 'react-redux'

import Section from './Section'
import SelectCurrentBranch from './SelectCurrentBranch'
import IsNoReportsMessage from './IsNoReportsMessage'
import ReportDescription from './ReportDescription'
import ReportSelect from './ReportSelect'
import Report from './Report'
import ReportParams from './ReportParams'
import ErrorText from './ErrorText'

const StandardLayout = React.memo((props) => {
  const {
    auth,
    handleCurrentBranchChange,
    handleReportChange,
    handleParamChange,
    fetchReport,
  } = props

  const {
    activeReport,
    branches,
    currentBranchId,
    error,
    reports,
    isReportLoading,
    reportData,
    reportRuntimeMs,
    isNoReports,
    isStickyHeader,
  } = useSelector(state => state)

  const reportParams = activeReport?.parsedReportParams
  const isAllParamsValid = reportParams?.every(p => p.isValid === true) ?? true

  const reportColumns = activeReport?.parsedReportColumns
  const currentBranch = branches.find(b => parseInt(b.BRANCH_ID, 10) === parseInt(currentBranchId, 10))

  return (
    <Container maxWidth="xl" disableGutters>
      {auth.username && ['paversreports', 'jonesreports'].includes(auth.username.toLowerCase()) && !currentBranchId
        ? (
          <Box width="100%" justifyContent="center" display="flex">
            <SelectCurrentBranch
              branches={branches}
              onChange={handleCurrentBranchChange}
              username={auth.username.toLowerCase()}
            />
          </Box>
        ) : (
          <Box display="flex" alignItems="center" width="100%" flexDirection="column">
            {error && (
              <Box width="100%" mb={2} display="flex" justifyContent="center">
                <ErrorText message={error} />
              </Box>
            )}
            {currentBranch && (
              <Box width="100%" mb={2} display="flex" justifyContent="center">
                <Typography variant="h4" component="h2">Branch: {currentBranch.BRANCH_DESC}</Typography>
              </Box>
            )}
            {isNoReports && (
              <Box width="100%" mb={2} display="flex" justifyContent="center">
                <Section>
                  <IsNoReportsMessage />
                </Section>
              </Box>
            )}
            {reports.length > 0 && (
              <Section>
                <ReportSelect
                  // activeReportID={activeReportID.current}
                  isDisabled={isReportLoading}
                  onChange={handleReportChange}
                  reports={reports}
                />
              </Section>
            )}
            {activeReport && activeReport.parsedReportOptions && activeReport.parsedReportOptions.description && activeReport.parsedReportOptions.description.trim().length > 0 && (
              <Box mt={2} width="100%">
                <Section>
                  <ReportDescription description={activeReport.parsedReportOptions.description} />
                </Section>
              </Box>
            )}
            {(reportParams || activeReport) && (
              <Box mt={2} width="100%">
                <Section>
                  {reportParams && (
                    <ReportParams
                      branches={branches}
                      currentBranchId={currentBranchId}
                      isDisabled={isReportLoading}
                      onParamChange={handleParamChange}
                      params={activeReport.parsedReportParams}
                    />
                  )}
                  {activeReport && (
                    <Box width="100%" mt={reportParams && reportParams.length > 0 ? 2 : 0} display="flex">
                      <Button
                        color="primary"
                        disabled={isReportLoading || !isAllParamsValid}
                        onClick={fetchReport}
                        variant="contained"
                      >
        Load data
                      </Button>
                    </Box>
                  )}
                </Section>
              </Box>
            )}
            {(isReportLoading || (activeReport && reportData)) && (
              <Box width="100%" mt={2}>
                <Section>
                  {isReportLoading && <CircularProgress /> }
                  {reportData && (
                    <Report
                      isLoading={isReportLoading}
                      reportColumns={reportColumns}
                      reportData={reportData}
                      reportOptions={activeReport.parsedReportOptions}
                      reportName={activeReport.reportName}
                      isStickyHeader={isStickyHeader}
                    />
                  )}
                  {reportRuntimeMs && (
                    <Box mt={1}>
                      <Typography variant="caption">
                        <em>{reportRuntimeMs}ms.</em>
                      </Typography>
                    </Box>
                  )}
                </Section>
              </Box>
            )}
          </Box>
        )}
    </Container>
  )
})

StandardLayout.propTypes = {
  auth: PropTypes.object.isRequired,
  currentBranchId: PropTypes.number,
  handleCurrentBranchChange: PropTypes.func.isRequired,
  handleReportChange: PropTypes.func.isRequired,
  handleParamChange: PropTypes.func.isRequired,
  fetchReport: PropTypes.func.isRequired,
}
StandardLayout.defaultProps = {
  currentBranchId: null,
}

StandardLayout.whyDidYouRender = true
StandardLayout.displayName = 'StandardLayout'

export default StandardLayout