import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import App from './App'

const Routes = (props) => {
  return (
    <Router>
      <Route exact path="/">
        <App {...props} />
      </Route>
      <Route path="/:reportSlug">
        <App {...props} />
      </Route>
    </Router>
  )
}

export default Routes
